import React from 'react';

const AnomalyDetection = React.lazy(() => import('../features/anomalyDetection/anomalyDetection'));
const AzureDatabricks = React.lazy(() => import('../features/azureDatabricks/AzureDatabricks'));
const AppTreeView = React.lazy(() => import('../features/appView/appTreeView'));
const DynamicView = React.lazy(() => import('../features/dynamicView/dynamicView'));
const Alert = React.lazy(() => import('../features/alert/AlertsList'));
const Resourcegroup = React.lazy(() => import('../features/resourcegroup/resourcegroup'));
const RolesAndPermissions = React.lazy(() =>
  import('../features/rolesAndpermissions/rolesAndpermissions')
);
const MeterCategoryView = React.lazy(() =>
  import('../features/meterCategoryView/meterCategoryView')
);
const TagGovernance = React.lazy(() => import('../features/tagGovernance/tagGovernance'));
const MultiTags = React.lazy(() => import('../features/multiTags/multiTags'));
const BuildYourOwn = React.lazy(() => import('../features/buildYourOwn/buildYourOwn'));
const AddUser = React.lazy(() => import('../features/addUser/addUser'));
const GrowthView = React.lazy(() => import('../features/growthView/growthView'));
// const Dashboard = React.lazy(() => import('../features/dashboard/dashboard'));
const Forecast = React.lazy(() => import('../features/forecast/forecast'));
const FinopsHealth = React.lazy(() => import('../features/finopsHealth/finopsHealth'));
const Anomalies = React.lazy(() => import('../features/anomalies/anomalies'));
const UserGroups = React.lazy(() => import('../features/userGroups/userGroups'));
const AppGroup = React.lazy(() => import('../features/appGroup/appGroup'));
const BusinessUnit = React.lazy(() => import('../features/appView/businessUnit'));
const TagKeyAudit = React.lazy(() => import('../features/tagKeyAudit/tagKeyAudit'));
const VoyaSolutions = React.lazy(() => import('../features/voyaSolutions/voyaSolutions'));
const TrendAnalysis = React.lazy(() => import('../features/trendAnalysis/trendAnalysis'));
const ReturnOfInvestment = React.lazy(() =>
  import('../features/returnOfInvestment/returnOfInvestment')
);

const appComponents = (key, config) => {
  switch (key) {
    case 'FinOps Health':
      return <FinopsHealth appConfig={config} />;
    case 'Custom Reports':
      return <DynamicView appConfig={config} />;
    case 'by Resource Group':
      return <Resourcegroup appConfig={config} />;
    case 'by Meter Category':
      return <MeterCategoryView appConfig={config} />;
    case 'Individual Tags':
      return <MultiTags appConfig={config} />;
    case 'Monthly Tag Maturity':
      return <TagGovernance appConfig={config} />;
    case 'Admin Center':
      return <AddUser appConfig={config} />;
    case 'Meter Growth':
      return <GrowthView appConfig={config} />;
    case 'Anomaly Insights':
      return <Anomalies appConfig={config} />;
    case 'Build Your Own 2':
      return <AppTreeView appConfig={config} />;
    case 'Build Your Own':
      return <BuildYourOwn appConfig={config} />;
    case 'Alerts':
      return <Alert appConfig={config} />;
    case 'Groups':
      return <UserGroups appConfig={config} />;
    case 'Grouped Applications':
      return <AppGroup appConfig={config} />;
    case 'Business Unit':
      return <BusinessUnit appConfig={config} />;
    case 'Module Access':
      return <RolesAndPermissions appConfig={config} />;
    case 'Azure Databricks':
      return <AzureDatabricks appConfig={config} />;
    case 'Anomaly Detection':
      return <AnomalyDetection appConfig={config} />;
    case 'Forecast Model':
      return <Forecast />;
    case 'Tag Key Auditor':
      return <TagKeyAudit />;
    case 'Voya Solutions':
      return <VoyaSolutions />;
    case 'Trend Analysis':
      return <TrendAnalysis />;
    case 'ROI Review':
      return <ReturnOfInvestment />;
    default:
      return (
        <div className="w-full h-[500px] flex justify-center items-center">
          <div className="text-violet-500">Coming soon...</div>
        </div>
      );
  }
};

export default appComponents;
