import { BsArrowReturnLeft } from 'react-icons/bs';
import { AiOutlineWarning } from 'react-icons/ai';
import { MdOutlineInsertDriveFile } from 'react-icons/md';
import { ProgressSpinner } from 'primereact/progressspinner';
import noResultsImage from '../../assets/icons/search.svg';

function SearchItem({ item, handleSelect, recentlyViewed }) {
  return (
    <button
      type="button"
      className="w-full border-b border-gray-300 cursor-pointer rounded-lg group h-14 md:p-3 md:px-4 px-3 py-2 flex items-center bg-gray-50 hover:bg-[#e2f1ff] justify-between"
      onClick={() => handleSelect(item)}>
      <div className="flex items-center !w-full">
        <MdOutlineInsertDriveFile className="mr-2 text-gray-500 group-hover:text-secondary text-2xl" />
        <div className="flex flex-col">
          <div className="text-sm text-gray-500 group-hover:text-secondary">{item}</div>
        </div>
      </div>
      {recentlyViewed && <div className="text-xs text-gray-400">Recently Viewed</div>}
      <div className="">
        <BsArrowReturnLeft className="group-hover:block hidden group-hover:text-secondary text-xl" />
      </div>
    </button>
  );
}

function FunctionalSearch({
  searchTerm,
  suggestions,
  showRecentlyViewed,
  showNoResults,
  handleSelect,
  searchHistory,
  isLoading
}) {
  const renderSearchItems = (items, title) => (
    <div
      className={`w-full text-xs text-left !text-[#0F83C4] px-3 ${
        searchHistory?.recent_searches ? 'mt-4' : ''
      }`}>
      {title}
      <div className="grid grid-cols-1 md:grid-cols-2 py-2 gap-2">
        {items.map((item) => (
          <SearchItem key={item?.label} item={item?.label} handleSelect={handleSelect} />
        ))}
      </div>
    </div>
  );

  return (
    (searchTerm || showRecentlyViewed) && (
      <div className="fixed mt-2 md:mt-[17rem] tracking-[0.3px]">
        <div className="relative flex shadow-lg w-[300px] sm:w-[500px] flex-wrap bg-white rounded-lg modal-container">
          <div className="w-full">
            {showRecentlyViewed && (
              <div className="w-full mt-5 shadow-lg">
                {searchHistory?.recent_searches?.length === 0 &&
                searchHistory?.recently_viewed?.length === 0 ? (
                  <div className="flex items-center justify-center w-full text-xs text-center pl-3 mb-2 py-10">
                    <span className="mr-1 text-purple-500">No Recently Searched</span>
                    <AiOutlineWarning className="text-orange-500 text-lg" />
                  </div>
                ) : (
                  <>
                    {searchHistory?.recently_viewed?.length > 0 &&
                      renderSearchItems(
                        searchHistory.recently_viewed,
                        'Recently Viewed Items are...'
                      )}
                    {searchHistory?.recent_searches?.length > 0 &&
                      renderSearchItems(
                        searchHistory.recent_searches,
                        'Recently Searched Items are...'
                      )}
                  </>
                )}
              </div>
            )}

            {isLoading ? (
              <div className="w-full flex items-center justify-center my-10">
                <ProgressSpinner className="!w-12 !h-12" strokeWidth="4" />
              </div>
            ) : (
              <>
                {suggestions?.length > 0 && !showRecentlyViewed && (
                  <div className="w-full mt-5 shadow-lg">
                    <div className="w-full text-xs text-left !text-[#b120da] pl-3 mb-2">
                      Searched Items are...
                    </div>
                    {suggestions.map((item) => (
                      <SearchItem
                        key={item?.label}
                        item={item?.label}
                        handleSelect={handleSelect}
                        recentlyViewed={item?.is_recently_viewed}
                      />
                    ))}
                  </div>
                )}
                {showNoResults && (
                  <div className="w-full flex flex-col items-center justify-center my-10">
                    <div className="w-32 h-32">
                      <img src={noResultsImage} alt="No results" className="w-32 h-32 mb-4" />
                    </div>
                    <p className="text-gray-900 text-xs md:text-base py-2">
                      We couldn&apos;t find anything matching your search.
                    </p>
                    <p className="text-gray-500 text-[12px] md:text-xs py-2">
                      Try again with a different term.
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    )
  );
}

export default FunctionalSearch;
