import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import constant from '../../constant';
import Spinner from '../../components/common/spinner';

function PageNotFound() {
  const permission = useSelector((state) => state.base.permission);
  return !permission ? (
    <Spinner />
  ) : (
    <main>
      <section className="bg-gradient-to-r from-gray-100 from-10%  to-blue-100 to-90% h-screen flex items-center ">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div className="mx-auto max-w-screen-sm text-center">
            <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-purple-600 ">
              404
            </h1>
            <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl">
              Something&apos;s missing.
            </p>
            <p className="mb-4 text-lg font-light text-gray-500 ">
              Sorry, we can&apos;t find that page. You&apos;ll find lots to explore on the home
              page.
            </p>
            <Link
              to={constant.dashboard_path}
              className="inline-flex text-white bg-purple-600 hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4">
              Back to Dashboard
            </Link>
          </div>
        </div>
      </section>
    </main>
  );
}

export default PageNotFound;
