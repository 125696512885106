import { configureStore } from '@reduxjs/toolkit';
import rootReduce from './rootReducer';
import api from './api';

const store = configureStore({
  reducer: rootReduce,
  middleware: (getDefaultMiddleWare) => getDefaultMiddleWare().concat(api.middleware)
});

export default store;
