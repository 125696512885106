/* eslint react/no-danger: "off" */
/* eslint no-console:"off" */
import { useEffect, useRef, useState } from 'react';
import { Tree } from 'primereact/tree';
import { Toast } from 'primereact/toast';
import { findNotificationCount, getFormattedNotificationsData } from '../../helpers/helper';
import { useUpdateReadNotificationMutation } from '../../features/dashboard/dashboardSlice';

function AlertPopup({ notificationsData, notificationsLoader, handleNotificationFetch }) {
  const toastRef = useRef(null);
  const [alertsData, setAlertsData] = useState([]);
  const [updateIsRead] = useUpdateReadNotificationMutation();
  useEffect(() => {
    setAlertsData(getFormattedNotificationsData(notificationsData));
  }, [notificationsData]);

  const customNodeTemplate = (e) => {
    return <p dangerouslySetInnerHTML={{ __html: e.label }} />;
  };

  const handleExpand = async (e) => {
    const idCollection = e?.node?.children.map((ele) => ele?.notification_id).join(',');
    try {
      if (idCollection) {
        await updateIsRead(idCollection);
        handleNotificationFetch();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderAlerts = () => {
    if (alertsData?.length === 0) {
      return (
        <p className="w-full text-center text-orange-500 py-4 font-neue-montreal tracking-[0.3px]">
          No Alerts
        </p>
      );
    }

    return (
      <div className=" alerts_popup max-h-[280px] overflow-y-scroll w-[368px] tracking-[0.3px]">
        <Tree
          value={alertsData}
          selectionMode="single"
          className="!rounded-none"
          nodeTemplate={customNodeTemplate}
          onExpand={handleExpand}
        />
      </div>
    );
  };
  return (
    <div className="tracking-[0.3px]">
      {!notificationsLoader && (
        <div className="bg-white rounded-md">
          <div className="flex items-center justify-start px-4 py-4 border-b border-gray-200 ">
            <h3 className="text-lg text-gray-950 font-semibold flex items-center font-neue-montreal">
              Notifications
              {findNotificationCount(notificationsData) > 0 && (
                <div className="bg-[#0F83C4] text-[12px] rounded-full text-white h-6 w-6 flex items-center justify-center ml-2 font-neue-montreal">
                  {findNotificationCount(notificationsData)}
                </div>
              )}
            </h3>
          </div>

          {renderAlerts()}
          <Toast ref={toastRef} />
        </div>
      )}
    </div>
  );
}

export default AlertPopup;
