import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  toast: {
    flag: false,
    message: '',
    heading: '',
    mode: null,
    life: 3000
  },
  amort: false,
  permission: null,
  jobId: false,
  chatWindowHistory: [
    {
      role: 'model',
      parts: [{ text: '👋 Hi there! How can I help?' }],
      hasQuery: false,
      isQueryShown: false,
      query: null
    }
  ],
  byoRoute: 'by Business App',
  newByoRoute: 'by Business App',
  routeView: 'Forecast',
  finOpsHealth: {
    cardData: {},
    pricingModelArr: [],
    tagMaturityData: {
      totalCount: 0,
      perKeyCount: 0,
      dropDownList: [],
      activeList: [],
      tagKeyObj: {
        totalKey: 0,
        keyCount: 0
      },
      selectedKey: 'All Keys'
    },
    negativeAnomaly: [],
    positiveAnomaly: [],
    forecastArr: []
  }
};

const baseSlice = createSlice({
  name: 'baseSlice',
  initialState,
  reducers: {
    showToast: (state, action) => {
      return {
        ...state,
        toast: {
          ...state.toast,
          ...action.payload,
          flag: true
        }
      };
    },
    closeToast: (state) => {
      return {
        ...state,
        toast: initialState.toast
      };
    },
    handleAmortChange: (state, action) => {
      return { ...state, amort: action.payload };
    },
    updateUserPermission: (state, action) => {
      return { ...state, permission: action.payload };
    },
    handleSyncChange: (state, action) => {
      return { ...state, jobId: action.payload };
    },
    handleByoChange: (state, action) => {
      return { ...state, byoRoute: action.payload };
    },
    handleNewByoChange: (state, action) => {
      return { ...state, newByoRoute: action.payload };
    },
    handleRouteViewChange: (state, action) => {
      return { ...state, routeView: action.payload };
    },
    handleBotHistory: (state, action) => {
      return { ...state, chatWindowHistory: action.payload };
    },
    handleSetCustomFilterData: (state, action) => {
      const {
        cardData,
        pricingModelArr,
        tagMaturityData,
        negativeAnomaly,
        positiveAnomaly,
        forecastArr
      } = action.payload;
      return {
        ...state,
        finOpsHealth: {
          ...state.finOpsHealth,
          cardData,
          pricingModelArr,
          tagMaturityData,
          negativeAnomaly,
          positiveAnomaly,
          forecastArr
        }
      };
    }
  }
});

export const {
  showToast,
  closeToast,
  handleByoChange,
  handleSyncChange,
  handleBotHistory,
  handleAmortChange,
  handleNewByoChange,
  updateUserPermission,
  handleRouteViewChange,
  handleSetCustomFilterData
} = baseSlice.actions;
export default baseSlice.reducer;
