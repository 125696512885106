import moment from 'moment';

/* eslint no-nested-ternary: "off" */
function createNestedArray(originalArray) {
  const nestedArray = [];
  let currentLevel = nestedArray;

  originalArray.forEach((item, index) => {
    const newItem = { id: item.id, column_name: item.column_name, label: item.label, children: [] };

    if (index === 0) {
      currentLevel.push(newItem);
    } else {
      const lastItem = currentLevel[currentLevel.length - 1];
      lastItem.children.push(newItem);
      currentLevel = lastItem.children;
    }
  });

  return nestedArray;
}
function formatNumberWithCommas(number) {
  return (
    Number(number)?.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }) || '0.00'
  );
}
function formatCommas(value) {
  return (Math.round(value) || 0).toLocaleString('en-US');
}

const getQueryParams = (location, key) => {
  const paramsObj = new URLSearchParams(location);
  return paramsObj.get(key);
};

const getAnnualValue = (data) => {
  if (data && data?.length > 0) {
    const sumActualCost = data?.reduce((accumulator, entry) => {
      return accumulator + parseFloat(entry.actual_cost);
    }, 0);

    return `$${formatCommas(sumActualCost?.toFixed(0))}`;
  }
  return '$0';
};

const getUptoActualValue = (data) => {
  if (data && data?.length > 0) {
    const sumActualCost = data
      ?.filter((entry) => parseInt(entry.month, 10) <= 9)
      .reduce((accumulator, entry) => {
        return accumulator + parseFloat(entry.actual_cost);
      }, 0);

    return `$${formatCommas(sumActualCost?.toFixed(0))}`;
  }
  return '$0';
};

const jsonParse = (jsonString) => {
  const payload =
    jsonString && typeof jsonString === 'string' && jsonString.includes('\n')
      ? JSON.parse(jsonString)
      : jsonString && typeof jsonString === 'string' && typeof JSON.parse(jsonString) === 'string'
      ? JSON.parse(JSON.parse(jsonString))
      : jsonString;
  return typeof payload === 'string' ? JSON.parse(payload) : payload;
};

function flatMap(array) {
  let result = [];
  if (array && Array.isArray(array) && array.length > 0) {
    // eslint-disable-next-line
    array.forEach(function (a) {
      result.push(a);
      if (Array.isArray(a.children)) {
        result = result.concat(flatMap(a.children));
      }
    });
  }

  return result.filter((doc) => doc.key).map(({ key, data }) => ({ key, data }));
}
const formatString = (str) => {
  let formattedStr = '';
  if (str && typeof str === 'string') {
    formattedStr = encodeURI(str).replace(/%20/g, '').replace(/%0A/g, ',').replace(/,{2,}/g, ',');
  }
  return formattedStr;
};

const currentDrillIncluder = (responseData, formattedResponse) => {
  const updatedFormattedResponse = responseData.map((element, index) => {
    const currentDrill = Object.keys(element)[0];
    return {
      ...formattedResponse[index],
      data: {
        ...formattedResponse[index].data,
        currentDrill
      }
    };
  });
  return updatedFormattedResponse;
};
const toUrlLowerCase = (string) => {
  let formattedStr = '';
  if (string && typeof string === 'string') {
    formattedStr = string.toLowerCase().split(' ').join('-');
  }
  return formattedStr;
};

const toolTipNameChanger = (name) => {
  switch (name) {
    case 'SubscriptionName':
      return 'Subscriptions';
    case 'ResourceGroup':
      return 'Resource Groups';
    case 'ResourceName':
      return 'Resources';
    case 'MeterCategory':
      return 'Meter Categories';
    case 'MeterSubCategory':
      return 'Meter Sub-Categories';
    case 'ResourceLocation':
      return 'Resource Locations';
    case 'Business Unit':
      return 'Business Units';
    case 'Application Name':
      return 'Applications';
    default:
      return name;
  }
};

const keyMapping = {
  MeterName: 'Meter Name',
  UnitOfMeasure: 'Unit of Measure',
  MeterCategory: 'Meter Category',
  MeterSubCategory: 'Meter Sub-Category',
  TotalCost: 'Cost'
};

function renameKeys(obj, keyMap) {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    newObj[keyMap[key] || key] = obj[key];
  });
  return newObj;
}

function getMaxLength(expandedKey = {}) {
  let maxLength = 0;
  Object.keys(expandedKey).forEach((key) => {
    const keyParts = key.split('-');
    const keyLength = keyParts.length > 1 ? keyParts.length : 1;
    if (keyLength > maxLength) {
      maxLength = keyLength;
    }
  });
  return maxLength;
}

function handleBreadCrumb(drillIndex, expandedKey) {
  if (drillIndex !== 0) {
    const newExpandedKey = Object.keys(expandedKey).reduce((result, key) => {
      const keyParts = key.split('-');
      if (keyParts.length < drillIndex) {
        const updatedResult = {
          ...result,
          [key]: expandedKey[key]
        };
        return updatedResult;
      }
      return result;
    }, {});
    return newExpandedKey;
  }
  return {};
}

function handleCollapse(event, expandedKey) {
  const { node } = event;
  const filteredObj = { ...expandedKey };
  delete filteredObj[node.key];
  Object.keys(filteredObj).forEach((objKey) => {
    if (objKey.startsWith(node.key) && objKey.split('-').length >= node.key.split('-').length) {
      delete filteredObj[objKey];
    }
  });
  return filteredObj;
}

function splitStringOnCapitalLetters(string) {
  // Use regular expression to split the string
  const result = string.split(/(?=[A-Z])/).map((str) => str.toLowerCase().trim());
  const finalResult = result.join('-');
  return finalResult;
}
const getRandomNumber = () => {
  return Math.floor(Math.random() * 100 + 1);
};

const filtrateColumn = (prev, actionColumn) => {
  const prevState = [...prev];
  const index = prevState.findIndex((doc) => doc.label.includes('Action'));
  if (index === -1) {
    prevState.push(actionColumn);
  }
  return prevState;
};

const getFormattedNotificationsData = (data) => {
  const headerNodes = [...new Set(data.map((ele) => ele.notification_category))];

  return headerNodes.map((ele, index) => {
    const childNodes = data.filter((doc) => doc.notification_category === ele);
    return {
      key: index,
      data: ele,
      selectable: false,
      label: `${ele} (${childNodes.length})`,
      children: childNodes
        .map((doc, indexChild) => ({
          key: `${index}-${indexChild}`,
          label: doc.notification_text,
          data: doc.notification_text,
          notification_link: doc.notification_link,
          notification_id: doc.id,
          selectable: false
        }))
        .reverse()
    };
  });
};

const formattedTableYearData = (data) => {
  const obj = {};
  if (Array.isArray(data) && data.length > 0) {
    for (const doc of data) {
      if (
        Object.hasOwnProperty.call(doc, 'display_date') &&
        Object.hasOwnProperty.call(doc, 'cost') &&
        Object.hasOwnProperty.call(doc, 'quantity')
      ) {
        const { display_date: key, cost, quantity } = doc;
        obj[key] = {
          cost,
          quantity
        };
      }
    }
  }
  return obj;
};

const formatTreeSelectKey = (nodeKeys) => {
  const columnKeys = Object.keys(nodeKeys);
  const optionKeys = [];
  const years = [];
  const months = [];
  for (const key of columnKeys) {
    if (key.includes('-')) {
      months.push(key);
    } else {
      years.push(key);
    }
  }
  for (const year of years) {
    for (const month of months) {
      if (month.includes(year)) {
        optionKeys.push({
          field: month,
          label: moment(new Date(month).toDateString()).format('MMMM YYYY')
        });
      }
    }
  }
  return optionKeys.sort((a, b) => {
    return new Date(a.field) - new Date(b.field);
  });
};

const updateFilterKeysCommon = (obj) => {
  const keys = [];
  if (obj && Object.keys(obj).length) {
    for (const key in obj) {
      if (
        Object.hasOwnProperty.call(obj, key) &&
        (key.includes('-') || Number.isNaN(Number(key)))
      ) {
        keys.push(key);
      }
    }
  }
  return keys;
};

const findNotificationCount = (data) => {
  let count = 0;
  data.forEach((ele) => {
    if (ele.is_read === false) count += 1;
  });
  return count;
};

function flattenArrayOfArrays(arrayOfArrays) {
  arrayOfArrays.shift();
  const flatArray = arrayOfArrays.flatMap((innerArray) => innerArray[0]);
  return [...new Set(flatArray)];
}

function compareTwoArrays(array1, array2) {
  if (array1.length === array2.length) {
    return array1.every((ele) => array2.includes(ele));
  }
  return false;
}

function findLabelByPath(data, myPath) {
  for (const type of data) {
    if (type.path === myPath) return type.label;
    if (type.additionalRoute) {
      const additionalLabel = type.additionalRoute.find((route) => route.path === myPath)?.label;
      if (additionalLabel) return additionalLabel;
    }
  }
  return null;
}

function wrapText(text, maxLength) {
  let result = '';
  let currentLineLength = 0;

  for (let i = 0; i < text?.length; i += 1) {
    if (currentLineLength >= maxLength && text[i] !== ' ') {
      result += '\n';
      currentLineLength = 0;
    }
    result += text[i];
    currentLineLength += 1;
  }

  return result;
}

export {
  createNestedArray,
  getQueryParams,
  formatNumberWithCommas,
  formatCommas,
  jsonParse,
  flatMap,
  formatString,
  toUrlLowerCase,
  toolTipNameChanger,
  splitStringOnCapitalLetters,
  currentDrillIncluder,
  keyMapping,
  renameKeys,
  getMaxLength,
  handleBreadCrumb,
  handleCollapse,
  getRandomNumber,
  filtrateColumn,
  getFormattedNotificationsData,
  formattedTableYearData,
  formatTreeSelectKey,
  updateFilterKeysCommon,
  findNotificationCount,
  flattenArrayOfArrays,
  compareTwoArrays,
  getAnnualValue,
  getUptoActualValue,
  findLabelByPath,
  wrapText
};
