/* eslint no-console:"off" */
/* eslint no-alert:"off" */
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { HiOutlineSearch, HiOutlineMenu } from 'react-icons/hi';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { IoMdClose } from 'react-icons/io';
import { OverlayPanel } from 'primereact/overlaypanel';
import '../../assets/css/common/cubeTable.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import { HiOutlineBell } from 'react-icons/hi2';
import { TbActivityHeartbeat } from 'react-icons/tb';
import { CgOrganisation } from 'react-icons/cg';
import {
  useSearchHistoryQuery,
  useLazySearchHistoryQuery,
  useLazyGetClearCacheQuery,
  useLazyGetHealthCheckQuery,
  useLazyGetNotificationsQuery,
  useLazyGetDatabaseDataQuery
} from '../../features/dashboard/dashboardSlice';
import logoLens from '../../assets/icons/logoLens.svg';
import AwsLogo from '../../assets/icons/AwsLogo.svg';
import AzureLogo from '../../assets/icons/AzureLogo.svg';
import OracleLogo from '../../assets/icons/OracleLogo.svg';
import {
  useGetUserDetailMutation,
  useUserLogoutMutation
} from '../../features/account/accountSlice';
import { showToast } from '../../features/base/baseSlice';
import { clearLocal, getLocalStroageData, setLocalStroage } from '../../helpers/LocalHelper';
import localConstant from '../../constant/localConstant';
import AlertPopup from './AlertPopup';
import FunctionalSearch from './functionalSearch';
import HealthCheck from './HealthCheck';
import { findNotificationCount, getFormattedNotificationsData } from '../../helpers/helper';

function NavBar({ handleHamburgerClick }) {
  const dispatch = useDispatch();
  const timeoutRef = useRef(null);
  const alertOverLayRef = useRef(null);
  const healthOverLayRef = useRef(null);
  const logoutOverLayRef = useRef(null);
  const dbSwitchOverRef = useRef(null);
  const avatarReference = useRef(null);
  const iconReference = useRef(null);
  const bellIconReference = useRef(null);
  const dbReference = useRef(null);
  const [userLogout] = useUserLogoutMutation();
  const navigate = useNavigate();
  const [suggestions, setSuggestions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const secondInputRef = useRef(null);
  const [showNoResults, setShowNoResults] = useState(false);
  const [showFunctionalSearch, setShowFunctionalSearch] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [alertsData, setAlertsData] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showRecentlyViewed, setShowRecentlyViewed] = useState(false);
  const [showSearchInput, setShowSearchInput] = useState();
  const [searchHistory, setSearchHistory] = useState([]);
  const [cacheLoading, setcacheLoading] = useState(false);
  const [notificationsData, setNotificationsData] = useState([]);
  const cloudPartner = getLocalStroageData(localConstant.CLOUD_PARTNER);
  const [getClearCache] = useLazyGetClearCacheQuery();
  const [
    getHealthCheck,
    { data: healthCheckData = {}, isError: healthCheckError, isFetching: healthLoader }
  ] = useLazyGetHealthCheckQuery();
  const [getDataBaseData, { data: dbData = [] }] = useLazyGetDatabaseDataQuery();

  const [
    getNotifications,
    {
      data: fetchedNotificationsData = [],
      isError: notificationsError,
      isFetching: notificationsLoader
    }
  ] = useLazyGetNotificationsQuery();

  const showToastMessage = (mode, heading, message) => {
    dispatch(
      showToast({
        mode,
        heading,
        message,
        autoClose: 100
      })
    );
  };

  const closeNotification = (alert) => {
    const updatedAlerts = alertsData.filter((item) => item.id !== alert.id);

    setAlertsData(updatedAlerts);
  };

  const [getUserDetail, { data }] = useGetUserDetailMutation();

  const [updateSearchHistory, { data: globalData, isLoading }] = useLazySearchHistoryQuery();
  const { data: recentHistoryData, isLoading: loading } = useSearchHistoryQuery(
    { value: '' },
    { skip: !isExpanded }
  );
  const handleFetchGlobalData = () => {
    const params = {};
    if (searchTerm.trim()) {
      params.value = `?search=${searchTerm}`;
    }
    updateSearchHistory(params);
  };

  const localUserData = getLocalStroageData(localConstant.USER);
  const userData =
    localUserData &&
    typeof localUserData === 'string' &&
    Object.keys(JSON.parse(localUserData)).length > 0
      ? JSON.parse(localUserData)
      : data;

  const handleDivClick = () => {
    if (searchTerm?.length === 0) {
      setShowRecentlyViewed(true);
      setIsExpanded(true);
      setSuggestions([]);
    }
  };
  const handleHamburgegAction = () => {
    setIsMenuOpen(!isMenuOpen);
    handleHamburgerClick();
  };

  const handleMobileClick = () => {
    setShowSearchInput(!showSearchInput);
  };
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchTerm(inputValue);
    setShowRecentlyViewed(false);
    if (inputValue.trim() === '') {
      setSuggestions([]);
    }
  };

  useEffect(() => {
    if (globalData) {
      setSuggestions(globalData?.results);
      setShowNoResults(false);
    } else {
      setSuggestions([]);
    }
  }, [globalData]);

  useEffect(() => {
    if (fetchedNotificationsData && fetchedNotificationsData.length) {
      setNotificationsData(fetchedNotificationsData);
    }
  }, [fetchedNotificationsData]);

  const handleSelect = (selectedValue) => {
    let selectedItem;
    if (searchHistory && searchTerm?.length === 0) {
      const filteredArray = [
        ...(searchHistory?.recently_viewed?.filter((item) => item?.label === selectedValue) || []),
        ...(searchHistory?.recent_searches?.filter((item) => item?.label === selectedValue) || [])
      ];
      selectedItem = filteredArray.find((item) => item?.label === selectedValue);
      navigate(`/${selectedItem?.link}`);
    }
    if (globalData && !showRecentlyViewed) {
      selectedItem = globalData?.results?.find((item) => item?.label === selectedValue);
      navigate(`/${selectedItem?.link}`);
    }

    if (selectedItem) {
      setSearchTerm('');
      setShowFunctionalSearch(false);
      setShowRecentlyViewed(false);
      updateSearchHistory({
        value: `?label=${selectedItem?.label}&link=${selectedItem?.link}&is_recent_search=true`
      });
    }
  };
  const handleNotificationFetch = () => {
    findNotificationCount(notificationsData);
    getNotifications();
    setAlertsData(getFormattedNotificationsData(notificationsData));
  };
  const handleOutsideClick = (event) => {
    const isClickedInsideInput =
      secondInputRef.current && secondInputRef.current.contains(event.target);
    const isClickedInsideRecentlySearched =
      secondInputRef.current &&
      secondInputRef.current.nextSibling &&
      secondInputRef.current.nextSibling.contains(event.target);

    if (isClickedInsideInput || isClickedInsideRecentlySearched) {
      return;
    }

    setShowFunctionalSearch(false);
    setSearchTerm('');
    setSuggestions([]);
    setShowRecentlyViewed(false);
    setIsExpanded(false);
  };
  useEffect(() => {
    setSearchHistory(recentHistoryData);
  }, [recentHistoryData]);

  useEffect(() => {
    handleNotificationFetch();
  }, []);

  useEffect(() => {
    if (showFunctionalSearch && secondInputRef.current) {
      secondInputRef.current.focus();
    }
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [showFunctionalSearch]);

  const handleLogout = () => {
    userLogout()
      .unwrap()
      .then(() => {
        clearLocal();
        navigate('/');
      })
      .catch((error) => {
        console.log(error);
        alert('Something went wrong');
      });
  };

  const handleDbSwitch = (item) => {
    const params = {
      database: item?.db_value
    };
    getDataBaseData(params)
      .unwrap()
      .then(() => {
        dbSwitchOverRef.current.hide();
        clearLocal();
        navigate('/');
        dispatch(
          showToast({
            mode: 'success',
            heading: 'Success',
            message: 'DB switched successfully.'
          })
        );
      });
  };

  const fetchUserDetail = () => {
    getUserDetail()
      .unwrap()
      .then((user) => {
        if (user && Object.keys(user).length) {
          setLocalStroage(localConstant.USER, JSON.stringify(user));
        }
      });
  };
  const toggleHealthPopup = (e) => {
    healthOverLayRef.current.toggle(e);
  };

  const toggleAlertPopup = (e) => {
    alertOverLayRef.current.toggle(e);
  };

  const handleFetchHealth = () => {
    getHealthCheck();
  };

  const handleDbFetch = () => {
    getDataBaseData();
  };

  const searchMobile = window.innerWidth <= 767;
  useEffect(() => {
    setShowSearchInput(searchMobile);
  }, [searchMobile]);

  useEffect(() => {
    let timeoutId;
    if (searchTerm && (!suggestions || (suggestions && suggestions.length === 0))) {
      timeoutId = setTimeout(() => {
        setShowNoResults(true);
      }, 400);
    } else {
      setShowNoResults(false);
    }

    return () => clearTimeout(timeoutId);
  }, [searchTerm, suggestions]);

  useEffect(() => {
    if (searchTerm?.length === 0 && isExpanded) {
      setShowRecentlyViewed(true);
    }
  }, [searchTerm]);

  useEffect(() => {
    const handleOutsideOverLayClick = (e) => {
      const popoverElement = logoutOverLayRef.current?.getElement();
      if (
        popoverElement &&
        !popoverElement.contains(e.target) &&
        !avatarReference.current.contains(e.target)
      ) {
        logoutOverLayRef.current.hide();
      }
    };

    document.addEventListener('mousedown', handleOutsideOverLayClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideOverLayClick);
    };
  }, [logoutOverLayRef, avatarReference]);

  useEffect(() => {
    const handleHealthOutsideOverLayClick = (e) => {
      const popoverElement = healthOverLayRef?.current?.getElement();
      if (
        popoverElement &&
        !popoverElement.contains(e.target) &&
        !iconReference.current.contains(e.target)
      ) {
        healthOverLayRef.current.hide();
      }
    };

    const notificationOutsideClick = (e) => {
      const popoverElement = alertOverLayRef?.current?.getElement();
      if (
        popoverElement &&
        !popoverElement.contains(e.target) &&
        !bellIconReference.current.contains(e.target)
      ) {
        alertOverLayRef.current.hide();
      }
    };
    const dbSwitchOutsideClick = (e) => {
      const popoverElement = dbSwitchOverRef?.current?.getElement();
      if (
        popoverElement &&
        !popoverElement.contains(e.target) &&
        !dbReference.current.contains(e.target)
      ) {
        dbSwitchOverRef.current.hide();
      }
    };

    document.addEventListener('mousedown', handleHealthOutsideOverLayClick);
    document.addEventListener('mousedown', notificationOutsideClick);
    document.addEventListener('mousedown', dbSwitchOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleHealthOutsideOverLayClick);
      document.removeEventListener('mousedown', notificationOutsideClick);
      document.removeEventListener('mousedown', dbSwitchOutsideClick);
    };
  }, [healthOverLayRef, iconReference, alertOverLayRef, bellIconReference, dbSwitchOverRef]);

  useEffect(() => {
    if (
      !(
        localUserData &&
        typeof localUserData === 'string' &&
        Object.keys(JSON.parse(localUserData)).length > 0
      )
    ) {
      fetchUserDetail();
    }
  }, [localUserData]);

  const onClearCache = async () => {
    try {
      setcacheLoading(true);
      const response = await getClearCache();
      const messages = response?.data?.message;

      if (response.error) {
        showToastMessage('error', 'Error', messages);
      } else {
        showToastMessage('success', 'Success', messages);
      }
    } catch (error) {
      showToastMessage('error', 'Error', 'something went wrong');
    } finally {
      setcacheLoading(false);
    }
  };

  let profileLabel = 'NA';
  if (userData && userData.name && typeof userData.name === 'string') {
    const nameSet = userData.name.toUpperCase().split(' ');
    profileLabel = nameSet.length > 1 ? nameSet.map((name) => name[0]) : nameSet[0].slice(0, 1);
  }

  const divClassName = isExpanded
    ? 'relative flex !w-[500px] !h-[36px] flex-wrap bg-white rounded-lg transition-all'
    : 'relative flex mt-2 sm:mt-0 !w-[300px] !h-[36px] sm:!w-96 flex-wrap bg-white rounded-lg transition-all';

  useEffect(() => {
    timeoutRef.current = setTimeout(handleFetchGlobalData, 500);
    return () => clearTimeout(timeoutRef.current);
  }, [searchTerm]);

  return (
    <header className="flex shadow bg-[#F9F9Fb] z-10 sticky top-0 flex-col md:flex-row tracking-[0.3px]">
      <div className="!w-full flex justify-between items-center pl-2 py-1 pr-2  ">
        <div
          className="hamburger-menu cursor-pointer md:hidden "
          aria-hidden="true"
          onClick={handleHamburgegAction}>
          {isMenuOpen ? (
            <IoMdClose className="h-6 w-6 transition-all duration-300 transform text-gray-500" />
          ) : (
            <HiOutlineMenu className="h-6 w-6 transition-all duration-300 text-gray-500" />
          )}
        </div>
        <div className="w-2/5 flex item-center md:w-3/12">
          <img src={logoLens} alt="logo" className="logo w-44" />
        </div>
        <div className="navbar-content flex ml-auto">
          <div className="functional-search flex tracking-[0.3px] ">
            <div
              aria-hidden="true"
              className="flex items-center justify-center h-full px-2 cursor-pointer md:hidden"
              onClick={handleMobileClick}>
              <HiOutlineSearch className="h-6 w-6 text-gray-500" />
            </div>
            <div className="functional-search hidden md:flex lg:flex xl:flex items-center">
              <div
                className={divClassName}
                aria-hidden
                onClick={handleDivClick}
                ref={secondInputRef}>
                <span className="z-8 h-full  leading-snug font-normal text-center text-slate-300 absolute bg-transparent rounded text-base items-center flex justify-center w-10 right-2  py-3">
                  {isLoading ? (
                    <ProgressSpinner
                      strokeWidth={4}
                      style={{ width: '20px', height: '20px' }}
                      color="#3b82f6"
                    />
                  ) : (
                    <HiOutlineSearch className="h-4.5 w-5 text-gray-500 " />
                  )}
                </span>
                <input
                  type="text"
                  value={searchTerm}
                  onChange={handleInputChange}
                  placeholder="Search"
                  className={` px-3 w-full !h-[35px] justify-between border rounded-lg py-1 text-gray-900 outline-none`}
                />
              </div>

              <FunctionalSearch
                searchHistory={searchHistory}
                searchTerm={searchTerm}
                suggestions={suggestions}
                isLoading={isLoading || loading}
                showRecentlyViewed={showRecentlyViewed}
                showNoResults={showNoResults}
                handleSelect={handleSelect}
              />
            </div>
            {cloudPartner === 'aws' && (
              <div className="w-[2.5rem] flex items-center justify-center mx-2">
                <img src={AwsLogo} alt="logo" />
              </div>
            )}
            {cloudPartner === 'azure' && (
              <div className="w-[5rem] flex items-center justify-center mx-2">
                <img src={AzureLogo} alt="logo" />
              </div>
            )}
            {cloudPartner === 'oracle' && (
              <div className="w-[5rem] flex items-center justify-center mx-2">
                <img src={OracleLogo} alt="logo" />
              </div>
            )}

            <div
              aria-hidden="true"
              ref={iconReference}
              className="flex items-center p-2 hover:bg-white hover:rounded-lg hover:border-[#4183C5] hover:border text-white transition-colors duration-300 cursor-pointer"
              onClick={toggleHealthPopup}>
              <TbActivityHeartbeat className="h-6 w-6 text-[#41415A] hover:text-[#4183C5]" />
            </div>
            <OverlayPanel ref={healthOverLayRef} onShow={handleFetchHealth}>
              <div className="w-[330px]">
                <HealthCheck
                  healthCheckData={healthCheckData}
                  isError={healthCheckError}
                  isLoading={healthLoader}
                />
              </div>
            </OverlayPanel>
          </div>
          <div className="other-contents flex">
            <div
              aria-hidden="true"
              ref={bellIconReference}
              className="flex items-center p-2 hover:bg-white hover:border-[#4183C5] hover:border hover:rounded-lg text-white transition-colors duration-300 cursor-pointer"
              onClick={(e) => toggleAlertPopup(e)}>
              <div className="relative  md:flex">
                <HiOutlineBell className="h-6 w-6 text-[#41415A] hover:text-[#4183C5]" />
                {findNotificationCount(notificationsData) > 0 && (
                  <div className="absolute bottom-3 left-3 w-4 h-4 bg-red-500 rounded-full flex  justify-center items-center">
                    <span className="text-white text-center text-xs font-bold ">
                      {findNotificationCount(notificationsData)}
                    </span>
                  </div>
                )}
                {notificationsLoader && (
                  <div className="absolute bottom-3 left-3 w-4 h-4 bg-red-500 rounded-full flex  justify-center items-center">
                    <i
                      className="pi pi-spin pi-spinner"
                      style={{ fontSize: '0.75rem', color: 'white' }}
                    />
                  </div>
                )}
                {notificationsError && (
                  <div className="absolute bottom-3 left-3 w-4 h-4 bg-red-500 rounded-full flex  justify-center items-center">
                    <i
                      className="pi pi-exclamation-circle"
                      style={{ fontSize: '0.75rem', color: 'white' }}
                    />
                  </div>
                )}
              </div>
            </div>
            <OverlayPanel ref={alertOverLayRef} onShow={handleNotificationFetch}>
              <div className="w-[360px] ">
                <AlertPopup
                  closeNotification={closeNotification}
                  notificationsData={notificationsData}
                  handleNotificationFetch={handleNotificationFetch}
                />
              </div>
            </OverlayPanel>
            <OverlayPanel ref={dbSwitchOverRef} dismissable>
              <div ref={dbReference} className="px-4 py-4 w-[150px] flex flex-col justify-center">
                {dbData && dbData?.list_database?.length > 0 ? (
                  dbData.list_database.map((item, index) => {
                    return (
                      <Button
                        label={item.db_name}
                        key={item.db_name}
                        type="submit"
                        className={`!p-0 !w-[120px] ${
                          dbData.list_database.length !== index + 1 ? '!mb-2' : '!mb-0'
                        }  !px-2 !hover:border-[#297cc5] !text-gray-400 ${
                          item.status === 'active' && '!bg-header !text-secondary'
                        } !text-[14px] dbBtn`}
                        outlined
                        onClick={() => handleDbSwitch(item)}
                      />
                    );
                  })
                ) : (
                  <div className="w-full h-[70px] flex justify-center items-center">
                    <ProgressSpinner className="flex justify-center items-center !w-[40px] !h-[40px] forecastspinner" />
                  </div>
                )}
              </div>
            </OverlayPanel>
            <div
              className="pl-2 cursor-pointer flex items-center hidden"
              aria-hidden="true"
              onClick={(e) => dbSwitchOverRef.current.toggle(e)}>
              <CgOrganisation
                className="h-6 w-6 md:h-8 md:w-8 text-gray-400"
                onClick={handleDbFetch}
              />
            </div>
            <OverlayPanel ref={logoutOverLayRef}>
              <div className="w-[234px] !font-neue-montreal tracking-[0.3px]">
                <div className="p-4 border-b">
                  <p className="font-medium text-gray-500">Profile</p>
                  <div className="w-full flex items-center my-2">
                    <div className="w-1/4">
                      <Avatar
                        label={profileLabel}
                        style={{ backgroundColor: '#297cc5', color: '#ffffff' }}
                      />
                    </div>
                    <div className="w-3/4">
                      <p className="text-sm">{userData && userData.name}</p>
                      <p className="text-xs w-full text-gray-400 truncate ...">
                        {userData && userData.email}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="pt-4 border-b">
                  <p className="py-3 px-4 hover:bg-gray-200 cursor-pointer">Personal Settings</p>
                  <p
                    className="py-3 px-4 hover:bg-gray-200 cursor-pointer"
                    aria-hidden="true"
                    disabled={cacheLoading}
                    onClick={onClearCache}>
                    Clear Cache
                  </p>
                  <p
                    className="py-3 px-4  hover:bg-gray-200 cursor-pointer"
                    aria-hidden="true"
                    onClick={handleLogout}>
                    Log out
                  </p>
                </div>
              </div>
            </OverlayPanel>
            <div
              className="pl-2 cursor-pointer flex items-center"
              aria-hidden="true"
              ref={avatarReference}
              onClick={(e) => logoutOverLayRef.current.toggle(e)}>
              <div
                className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full"
                style={{
                  border: '1px solid #0F6CBD',
                  color: 'white',
                  backgroundColor: '#4183C5',
                  fontWeight: 'bold',
                  borderRadius: '8px'
                }}>
                <span className="font-normal text-white-600 dark:text-white-500 text-[18px]">
                  {profileLabel}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {!showSearchInput && searchMobile && (
        <div className="  flex justify-center items-center md:hidden ">
          <div className="functional-search  md:flex ">
            <div
              className="relative flex mt-2 sm:mt-0 !w-[300px] sm:!w-[500px] flex-wrap sm:pr-2 bg-white rounded-lg"
              aria-hidden
              onClick={handleDivClick}
              ref={secondInputRef}>
              <span className="z-8 h-full  leading-snug font-normal text-center text-slate-300 absolute bg-transparent rounded text-base flex items-center justify-center w-10 right-2  py-3">
                {isLoading ? (
                  <ProgressSpinner
                    strokeWidth={4}
                    style={{ width: '20px', height: '20px' }}
                    color="#3b82f6"
                  />
                ) : (
                  <HiOutlineSearch className="h-4.5 w-5 text-gray-500 " />
                )}
              </span>
              <input
                type="text"
                value={searchTerm}
                onChange={handleInputChange}
                placeholder="Search"
                className={` px-3 w-full  justify-between border rounded-lg py-2 text-gray-900 outline-none`}
              />
            </div>
            <FunctionalSearch
              searchHistory={searchHistory}
              searchTerm={searchTerm}
              isLoading={isLoading || loading}
              showRecentlyViewed={showRecentlyViewed}
              suggestions={suggestions}
              showNoResults={showNoResults}
              handleSelect={handleSelect}
            />
          </div>
        </div>
      )}
    </header>
  );
}

export default NavBar;
