/* eslint no-nested-ternary: "off" */
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import { classNames } from 'primereact/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { GoTriangleDown } from 'react-icons/go';
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { HiOutlineCloudArrowDown } from 'react-icons/hi2';
import { BiUser } from 'react-icons/bi';
import { BsCalendarCheck, BsShieldCheck } from 'react-icons/bs';
import { jwtDecode } from 'jwt-decode';
import { clearLocal, getLocalStroageData, setLocalStroage } from '../../helpers/LocalHelper';
import constant from '../../constant';
import localConstant from '../../constant/localConstant';
import '../../assets/css/SignIn/signin.css';
import {
  useLazyGetUserPermissionQuery,
  useLazyOrgOverViewQuery,
  useUserLoginMutation
} from './accountSlice';
import { showToast, updateUserPermission } from '../base/baseSlice';
import Asset from '../../assets/icons/Asset.svg';
import CubeLogo from '../../assets/icons/logo.svg';
import AwsLogo from '../../assets/icons/AwsLogo.svg';
import AzureLogo from '../../assets/icons/AzureLogo.svg';
import OracleLogo from '../../assets/icons/OracleLogo.svg';
import { formatCommas } from '../../helpers/helper';
import { useLazyGetDatabaseDataQuery } from '../dashboard/dashboardSlice';
import { formatMonthIntoShort } from '../../helpers/DateHelper';

export default function SignIn() {
  const navigate = useNavigate();
  const toast = useRef(null);
  const cloudPartnerLocal = getLocalStroageData(localConstant.CLOUD_PARTNER);
  const token = getLocalStroageData(localConstant.TOKEN);
  const refreshToken = getLocalStroageData(localConstant.REFRESH);
  const permit = getLocalStroageData(localConstant.PERMIT);
  const [userLogin, { isSuccess, data, isError, isLoading }] = useUserLoginMutation();
  const [getPermission, { isFetching: permissionLoader }] = useLazyGetUserPermissionQuery();
  const [getCardData, { data: cardData, isLoading: cardLoading }] = useLazyOrgOverViewQuery();
  const [getDataBaseData, { data: dbData = [], isLoading: isDbLoading }] =
    useLazyGetDatabaseDataQuery();

  const dispatch = useDispatch();
  const { search } = useLocation();
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);
  const [cloudCardData, setCloudCardData] = useState([]);

  const postLoginData = async (user) => {
    userLogin(user);
  };

  const formSubmit = (formValue, { resetForm }) => {
    const params = {
      database: formValue?.Db?.db_value,
      email: formValue?.email
    };
    if (formValue?.Db?.db_value) {
      getDataBaseData(params)
        .unwrap()
        .then(() => {
          postLoginData(formValue, resetForm);
        });
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required('Email is required'),
    password: Yup.string().required('Password is required'),
    Db: Yup.object().required('Select Organization')
  });
  const { values, handleChange, handleBlur, touched, handleSubmit, errors, setFieldValue } =
    useFormik({
      initialValues: {
        email: '',
        password: ''
      },

      validationSchema,
      onSubmit: formSubmit
    });

  const isFormFieldInvalid = (name) => !!(touched[name] && errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{errors[name]}</small>
    ) : (
      <small className="p-error" />
    );
  };

  const handleCloudSelect = (selected) => {
    setLocalStroage(localConstant.CLOUD_PARTNER, selected);
    window.location.reload();
    // navigate(constant.dashboard_path);
  };

  const openMsal = () => {
    // window.location.href = constant.msal_redirect_url;
  };

  React.useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const paramsToken = searchParams.get(localConstant.PARAM_TOKEN_KEY);
    if (token && permit === 'true' && refreshToken && cloudPartnerLocal?.length > 0) {
      if (cloudPartnerLocal) {
        try {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000;
          if (decodedToken.exp < currentTime) {
            // Token verification failed
            clearLocal();
            window.location.href = '/';
          } else {
            // Token is valid
            navigate(constant.finops_health_path);
          }
        } catch (error) {
          // Token verification failed
          clearLocal();
          window.location.href = '/';
        }
      }
    }
    if (paramsToken) {
      setLocalStroage(localConstant.TOKEN, paramsToken);
      if (cloudPartnerLocal) {
        navigate(constant.finops_health_path);
      }
    }
  });

  const handleCardData = async () => {
    getCardData();
  };

  const handleDbFetch = () => {
    getDataBaseData();
  };

  React.useEffect(() => {
    if (isSuccess) {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'You have logged in successfully',
        id: 'success_element_id'
      });
      handleCardData();
      const authToken = data.token ? data.token : '';
      const refresh = data.refresh_token ? data.refresh_token : '';
      const aiToken = data.ai_token ? data.ai_token : '';
      setLocalStroage(localConstant.REFRESH, refresh);
      setLocalStroage(localConstant.TOKEN, authToken);
      setLocalStroage(localConstant.AI, aiToken);
      getPermission()
        .unwrap()
        .then((response) => {
          dispatch(updateUserPermission(response));
          setLocalStroage(localConstant.PERMIT, true);
          setIsLoginSuccess(true);
        })
        .catch(() => {
          dispatch(
            showToast({
              mode: 'error',
              heading: 'Error',
              message: 'Something went wrong'
            })
          );
        });
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (dbData && dbData?.list_database?.length > 0) {
      const defaultSelected = dbData?.list_database?.filter((doc) => doc.status === 'active');
      setFieldValue('Db', defaultSelected[0]);
    }

    if (cardData) {
      setCloudCardData(cardData);
    }
  }, [cardData, dbData]);

  useEffect(() => {
    handleDbFetch();
  }, []);
  return (
    <div className="h-screen sign-in grid md:grid-cols-1 lg:grid-cols-2 sm:grid-cols-1">
      <div className="col-span-1 flex h-screen sign-in row-start-2 row-span-2 lg:row-start-1">
        <div className="flex-1 bg-[#297cc5] flex flex-col justify-center items-center relative ">
          <img
            src={Asset}
            alt="backGround"
            className="w-full h-full  absolute opacity-30	object-cover"
          />
          <div className="w-5/6 flex justify-center items-center text-white lg:h-3/4 md:mt-16 h-3/4">
            <div>
              <h2 className="text-xl py-0 mt-20 lg:py-4 lg:text-3xl md:text-2xl pb-2 font-bold">
                Cloud Financial Operations
              </h2>
              <ul className="text-lg list-none mt-0 lg:mt-6">
                <li className="flex flex-wrap items-center justify-center md:flex-nowrap py-4">
                  <div className="w-[60px] h-[60px] bg-blue-700 rounded-lg flex items-center justify-center mr-3 lg:w-[100px] lg:h-[100px] md:w-[100px] md:h-[100px]">
                    <HiOutlineCloudArrowDown className="text-white text-3xl lg:text-5xl md:text-5xl" />
                  </div>
                  <span className="text-sm py-2  lg:ml-2 lg:text-base md:text-base w-3/4">
                    Streamline your cloud operations with full automation and transparency. With our
                    intelligent cloud cost management solution, you can save time, reduce effort,
                    and achieve up to 70% in cost savings.
                  </span>
                </li>
                <li className="flex flex-wrap items-center justify-center md:flex-nowrap py-4">
                  <div className="w-[60px] h-[60px] bg-blue-700 rounded-lg flex items-center justify-center mr-3 lg:w-[100px] lg:h-[100px] md:w-[100px] md:h-[100px]">
                    <BsShieldCheck className="text-white text-3xl lg:text-5xl md:text-5xl" />
                  </div>
                  <span className="text-sm py-2 lg:ml-2 lg:text-base md:text-base w-3/4">
                    Gain detailed reports and analytics to see exactly where your cloud spend is
                    going and detect and prevent anomalies in real-time.
                  </span>
                </li>
                <li className="flex flex-wrap items-center justify-center md:flex-nowrap py-4">
                  <div className="w-[60px] h-[60px] bg-blue-700 rounded-lg flex items-center justify-center mr-3 lg:w-[100px] lg:h-[100px] md:w-[100px] md:h-[100px]">
                    <BsCalendarCheck className="text-white text-3xl lg:text-5xl md:text-5xl" />
                  </div>
                  <span className="text-sm py-2 lg:ml-2 lg:text-base md:text-base w-3/4">
                    Say goodbye to unpleasant billing surprises and hello to smarter cloud cost
                    management.
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <ul className="flex pl-5 space-z-0 lg:space-x-4 z-10 lg:mt-8 lg:mb-32 md:mt-16 py-8">
            <li>
              <span className="text-sm  pr-1 text-gray-200 hover:text-white lg:text-base md:text-base">
                About Us
              </span>
            </li>
            <span className="text-gray-200 mr-1 border-r-2" />
            <li>
              <span className="text-sm pr-1 text-gray-200 hover:text-white lg:text-base md:text-base">
                Privacy Policy
              </span>
            </li>
            <span className="text-gray-200 mr-1 border-r-2" />
            <li>
              <span className=" text-sm pr-1 text-gray-200 hover:text-white lg:text-base md:text-base">
                Cookies Policy
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="col-span-1 relative flex h-screen sign-in ">
        <div className="flex-1 flex justify-center items-center">
          {/* Login form */}
          <Toast ref={toast} position="bottom-right" />
          {isLoginSuccess ? (
            <div>
              <div className="flex flex-col h-screen justify-evenly">
                {cardLoading ? (
                  <Skeleton width="488px" height="244px" className="loginSkeletonAzure" />
                ) : (
                  <div className="cloudSelectCard flex justify-center space-x-2 h-min w-min shadow-md rounded-lg p-3 hover:shadow-2xl bg-blue-100">
                    <div className="flex items-center justify-between flex-col">
                      <div className="w-[13rem]">
                        <img src={AzureLogo} alt="logo" />
                      </div>
                      <div className="w-full">
                        {' '}
                        <button
                          type="button"
                          onClick={() => handleCloudSelect('azure')}
                          className="bg-[#EFF9FF] rounded-md text-blue-400 text-xl h-11 w-full font-semibold transition-transform transform hover:scale-105 shadow-md hover:shadow-lg">
                          Open
                        </button>
                      </div>
                    </div>
                    <div className="bg-blue-400 w-[1px] max-h-full" />
                    <div className="flex justify-center items-start flex-col space-y-4">
                      <div className="bg-blue-400 rounded-lg w-[15rem] text-right pr-2 py-1">
                        <p className="text-2xl font-semibold text-white">
                          ${formatCommas(cloudCardData?.azure_data?.monthly_cost)}/Mo
                        </p>
                        <p className="text-[15px] text-white">Last 3 months average</p>
                      </div>
                      <div className="bg-blue-400 rounded-lg w-[15rem] text-right pr-2">
                        <p className="text-base text-white my-2">
                          {cloudCardData?.azure_data?.subscription_count} Subscriptions
                        </p>
                        <p className="text-base text-white my-2">
                          {' '}
                          {formatMonthIntoShort(cloudCardData?.azure_data?.last_updated_on)} MTD - $
                          {formatCommas(cloudCardData?.azure_data?.mtd_azure)}
                        </p>
                        <p className="text-base text-white my-2">
                          {formatCommas(cloudCardData?.azure_data?.resource_count)} resources
                        </p>
                      </div>
                      <div className="w-[15rem] text-right pr-2">
                        <p className="text-[15px] font-light text-[#595959]">
                          Last updated on {cloudCardData?.azure_data?.last_updated_on}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {cardLoading ? (
                  <Skeleton width="488px" height="244px" className="loginSkeletonAws" />
                ) : (
                  <div className="cloudSelectCard flex justify-center space-x-2 h-min w-min shadow-md rounded-lg p-3 hover:shadow-2xl bg-[#FFE4BD]">
                    <div className="flex items-center justify-between flex-col">
                      <div className="w-[13rem] flex justify-center items-center">
                        <div className="w-[10rem] mt-5">
                          <img src={AwsLogo} alt="logo" />
                        </div>
                      </div>
                      <div className="w-full">
                        {' '}
                        <button
                          type="button"
                          // disabled={values.Db.db_value === '1431_CloudDB2'}
                          onClick={() => handleCloudSelect('aws')}
                          className={`bg-[#FFF3E1] ${
                            values.Db.db_value === '1431_CloudDB2'
                              ? 'cursor-pointer'
                              : 'cursor-pointer'
                          } rounded-md text-[#FF9900] text-xl h-11 w-full font-semibold transition-transform transform hover:scale-105 shadow-md hover:shadow-lg`}>
                          Open
                        </button>
                      </div>
                    </div>
                    <div className="bg-[#FF9900] w-[1px] max-h-full" />
                    <div className="flex justify-center items-start flex-col space-y-4">
                      <div className="bg-[#FF9900] rounded-lg w-[15rem] text-right pr-2 py-1">
                        <p className="text-2xl font-semibold text-white">
                          ${formatCommas(cloudCardData?.aws_data?.monthly_cost)}/Mo
                        </p>
                        <p className="text-[15px] text-white">Last 3 months average</p>
                      </div>
                      <div className="bg-[#FF9900] rounded-lg w-[15rem] text-right pr-2">
                        <p className="text-base text-white my-2">
                          {formatCommas(cloudCardData?.aws_data?.accounts_count)} Accounts
                        </p>
                        <p className="text-base text-white my-2">
                          {' '}
                          {formatMonthIntoShort(cloudCardData?.aws_data?.last_updated_on)} - $
                          {formatCommas(cloudCardData?.aws_data?.mtd_aws)}
                        </p>
                        <p className="text-base text-white my-2">
                          {formatCommas(cloudCardData?.aws_data?.resource_count)} resources
                        </p>
                      </div>
                      <div className="w-[15rem] text-right pr-2">
                        <p className="text-[15px] font-light text-[#595959]">
                          Last updated on {cloudCardData?.aws_data?.last_updated_on}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {cloudCardData?.oracle_data ? (
                  cardLoading ? (
                    <Skeleton width="488px" height="244px" className="loginSkeletonOracle" />
                  ) : (
                    <div
                      className={`cloudSelectCard flex justify-center space-x-2 h-min w-min shadow-md rounded-lg p-3 hover:shadow-2xl bg-red-200 ${
                        values.Db.db_value === '1431_CloudDB2' && 'cursor-not-allowed'
                      }`}>
                      <div className="flex items-center justify-between flex-col">
                        <div className="w-[13rem] flex justify-center items-center">
                          <div className="w-[10rem] mt-5">
                            <img src={OracleLogo} alt="logo" />
                          </div>
                        </div>
                        <div className="w-full">
                          {' '}
                          <button
                            type="button"
                            onClick={() => handleCloudSelect('oracle')}
                            className={`bg-[#FFF3E1] ${
                              values.Db.db_value === '1431_CloudDB2'
                                ? 'cursor-pointer'
                                : 'cursor-pointer'
                            } rounded-md text-[#DF2C2B] text-xl h-11 w-full font-semibold transition-transform transform hover:scale-105 shadow-md hover:shadow-lg`}>
                            Open
                          </button>
                        </div>
                      </div>
                      <div className="bg-[#DF2C2B] w-[1px] max-h-full" />
                      <div className="flex justify-center items-start flex-col space-y-4">
                        <div className="bg-[#DF2C2B] rounded-lg w-[15rem] text-right pr-2 py-1">
                          <p className="text-2xl font-semibold text-white">
                            ${formatCommas(cloudCardData?.oracle_data?.monthly_cost)}/Mo
                          </p>
                          <p className="text-[15px] text-white">Last 3 months average</p>
                        </div>
                        <div className="bg-[#DF2C2B] rounded-lg w-[15rem] text-right pr-2">
                          <p className="text-base text-white my-2">
                            {formatCommas(cloudCardData?.oracle_data?.subscription_count)} Accounts
                          </p>
                          <p className="text-base text-white my-2">
                            {' '}
                            {formatMonthIntoShort(cloudCardData?.oracle_data?.last_updated_on)} - $
                            {formatCommas(cloudCardData?.oracle_data?.mtd_oracle)}
                          </p>
                          <p className="text-base text-white my-2">
                            {formatCommas(cloudCardData?.oracle_data?.oracleResourceCount)}{' '}
                            resources
                          </p>
                        </div>
                        <div className="w-[15rem] text-right pr-2">
                          <p className="text-[15px] font-light text-[#595959]">
                            Last updated on {cloudCardData?.oracle_data?.last_updated_on}
                          </p>
                        </div>
                      </div>
                    </div>
                  )
                ) : null}
              </div>
            </div>
          ) : (
            <div className="w-5/6 h-3/4  flex justify-center items-center lg:w-1/2 p-2 md:4/5">
              <form className="pt-5 w-[100%]" onSubmit={handleSubmit}>
                <div className="text-center w-full flex justify-center hidden">
                  <div className="w-[15rem]">
                    <img src={CubeLogo} alt="logo" className="mb-4" />
                  </div>
                </div>
                <div className="my-3">
                  <h1 className="text-2xl py-1 text-[#297cc5] font-roboto font-semibold	">
                    Hi, Welcome Back!
                  </h1>
                  <h6 className="text-xs mb-3 font-roboto text-gray-500">
                    Log in to view your cloud spend
                  </h6>
                </div>

                <button
                  type="button"
                  className="flex w-full border rounded-md border-[#297cc5] h-[40px] justify-center items-center my-3 cursor-pointer "
                  onClick={openMsal}>
                  <img
                    className="w-[20px] h-[20px] bg-white"
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRLAMSdsPWEms9TC-0lrWMcGBA9zn_wNfqoTvxTgTKa3oRpjdL5w-7EQ-vRwSlo7vnHQjQ&usqp=CAU"
                    alt="logo"
                  />
                  <small className="mx-2 font-semibold">Continue With Microsoft</small>
                </button>

                <div className="flex my-3 justify-center items-center">
                  <div className="border h-[2px] rounded-md w-full border-grey-600" />
                  <small className="mx-2">OR</small>
                  <div className="border h-[2px] rounded-md w-full border-grey-600" />
                </div>
                <div className="w-full my-2">
                  <small className="my-2 text-sm font-medium">Email ID</small>
                </div>
                <span className="p-input-icon-right w-full">
                  <BiUser className="w-[20px] h-[20px] text-[#297cc5] " />
                  <InputText
                    value={values.email}
                    onChange={handleChange}
                    name="email"
                    width="100%"
                    placeholder="Email"
                    onBlur={handleBlur}
                    className={`w-full border rounded-md border-[#297cc5] h-[40px] ${classNames({
                      'p-invalid': isFormFieldInvalid('email')
                    })}`}
                  />
                </span>
                {getFormErrorMessage('email')}
                <div className="w-full my-2">
                  <small className="my-2 text-sm font-medium">Password</small>
                </div>
                <Password
                  value={values.password}
                  onChange={handleChange}
                  toggleMask
                  name="password"
                  placeholder="Password"
                  onBlur={handleBlur}
                  className={`w-full password_field ${classNames({
                    'p-invalid': isError || isFormFieldInvalid('password')
                  })} h-[40px] my-1 bg-white border-red-500`}
                  feedback={false}
                />
                {isError ? (
                  <small className="p-error">Invalid Credentials!</small>
                ) : (
                  getFormErrorMessage('password')
                )}
                <div className="w-full my-2">
                  <small className="my-2 text-sm font-medium">Select Organization</small>
                </div>
                <div className="flex">
                  <Dropdown
                    value={values.Db}
                    onChange={(e) => {
                      setFieldValue('Db', e.value);
                    }}
                    name="Db"
                    options={dbData?.list_database}
                    optionLabel="db_name"
                    placeholder="Select a DB"
                    dropdownIcon={<GoTriangleDown className="text-secondary text-lg" />}
                    className="!w-full h-[40px] dropdownlog"
                    emptyMessage="No DB's found"
                  />
                </div>
                {getFormErrorMessage('Db')}
                <div className="flex items-center justify-between mt-4">
                  <div className="py-4">
                    <span className="text-[#297cc5] text-xs cursor-pointer">Forgot password ?</span>
                  </div>
                  <Button
                    type="submit"
                    loading={isLoading || permissionLoader || isDbLoading}
                    className="!p-0 !px-8 !py-2.5 !rounded-lg text-xs !bg-[#297cc5] !text-white hover:!bg-blue-700 !border-none loading_btn">
                    Log in
                  </Button>
                </div>
                <div className="flex justify-start items-center my-5">
                  <small className="text-xs font-roboto text-gray-500">
                    Don&apos;t have an account?
                  </small>
                  <small className="text-[#297cc5] mx-1 font-semibold cursor-pointer">
                    Sign Up
                  </small>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
