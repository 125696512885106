const routerPath = {
  home_path: '/',
  dashboard_path: '/dashboard',
  finops_health_path: '/finops-health',
  resource_group_path: '/cost-analysis/by-resource-group',
  meter_category_path: '/cost-analysis/by-meter-category',
  technology_path: '/technology-services',
  business_path: '/business-services',
  dynamic_view_path: '/cost-analysis/reports',
  view_list_path: '/view-list',
  tag_governance_path: '/tag-governance/monthly-tag-maturity',
  tag_governance_multiple_path: '/tag-governance/individual-tags',
  Tree_view_list_path: '/Tree-view-list',
  add_user_path: '/admin-center/users',
  anomalies_path: '/cost-analysis/anomaly-insights',
  view: '/view',
  user_groups: '/admin-center/groups',
  custom_app_view: '/build-your-own/assemble-application',
  growth_view: '/cost-analysis/meter-growth',
  alert: '/alerts',
  custom_app_tree_view: '/build-your-own/assemble-application-tree-view',
  custom_app_group: '/build-your-own/grouped-applications-tree-view',
  anomalies: '/cost-analysis/anomalies',
  roles_and_permissions: '/admin-center/module-access',
  recommendation: '/recommendation'
};

export default routerPath;
