import DonutChart from './EDoughnut';
import healthCheck from '../../assets/icons/healthCheck.svg';

function HealthCheck({
  healthCheckData: { externalServices, diskSpaceUsed, memoryUsed, cpuUsage },
  isError,
  isLoading
}) {
  const { status } = externalServices ?? {};
  const buttonColor = status ? '#7CBB00' : '#dc2626';

  const renderPopup = () => {
    if (isError) {
      return (
        <div className="h-[210px] w-full grid place-content-center">
          <img src={healthCheck} alt="healthCheck" className="w-48 h-40" />
          <p className="text-gray-950 flex items-center justify-center">Something went wrong!</p>
        </div>
      );
    }
    return (
      <div className="!font-neue-montreal tracking-[0.3px]">
        <div className="bg-white rounded-md">
          <div className="flex items-center justify-between px-4 py-4 border-b border-gray-200 ">
            <h3 className="text-lg text-gray-950 font-semibold flex items-center">
              Health Check Status
            </h3>
            <div className="flex items-center pt-[0.375rem]">
              <button
                type="button"
                className="text-white flex items-center text-xs hover:bg-white focus:outline-none focus:ring-1 focus:ring-white rounded-lg font-medium px-5 py-1 text-center mr-2 mb-2"
                style={{ backgroundColor: buttonColor, borderColor: buttonColor }}>
                {status ? 'Active' : 'Inactive'}
              </button>
            </div>
          </div>
          <div className="grid grid-cols-3">
            <div className="flex">
              <DonutChart data={{ name: 'CPU', value: cpuUsage }} loading={isLoading} />
            </div>
            <div className="flex">
              <DonutChart data={{ name: 'Memory', value: memoryUsed }} loading={isLoading} />
            </div>
            <div className="flex">
              <DonutChart data={{ name: 'Storage', value: diskSpaceUsed }} loading={isLoading} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return renderPopup();
}

export default HealthCheck;
