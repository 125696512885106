/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { HiChevronDoubleLeft } from 'react-icons/hi2';
import { Tooltip } from 'primereact/tooltip';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useLazyGetNotificationsQuery } from '../../features/dashboard/dashboardSlice';
import { findLabelByPath } from '../../helpers/helper';

const labelFormatter = (str) => {
  return str.replace(/-/g, ' ');
};

const usage = [
  { label: 'CXO Review', minHeight: 'h-[160px]' },
  { label: 'FinOps Central', minHeight: 'h-[400px]' },
  { label: 'Suggestion Engine', minHeight: 'h-[160px]' },
  { label: 'IAM', minHeight: 'h-[80px]' }
];

function SideBar({ sideNavs, isMobileView }) {
  const [expandState, setExpandState] = useState(null);
  const [panelIndex, setPanelIndex] = useState(0);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [getNotifications] = useLazyGetNotificationsQuery();

  const handleExpand = (key) => {
    setExpandState((prev) => (prev === key ? null : key));
  };

  const handleClose = () => {
    setExpandState(null);
  };

  const getSelectedPanel = (selectedPath) => {
    const selectedPanel = findLabelByPath(sideNavs, selectedPath);
    const isCXO = selectedPanel === 'FinOps Health' || selectedPanel === 'ROI Review';
    const isCentral =
      selectedPanel === 'Custom Reports' ||
      selectedPanel === 'Voya Solutions' ||
      selectedPanel === 'Tag Key Auditor' ||
      selectedPanel === 'Monthly Tag Maturity' ||
      selectedPanel === 'Alerts' ||
      selectedPanel === 'Build Your Own' ||
      selectedPanel === 'Azure Databricks';
    const isSuggEngine =
      selectedPanel === 'Forecast Model' || selectedPanel === 'Anomaly Detection';
    const isIAM = selectedPanel === 'Admin Center';
    if (isCXO) {
      setPanelIndex(0);
    } else if (isCentral) {
      setPanelIndex(1);
    } else if (isSuggEngine) {
      setPanelIndex(2);
    } else if (isIAM) {
      setPanelIndex(3);
    }
  };

  const handleHoveredPanel = (label) => {
    const isCxoHovered = label === 'FinOps Health' || label === 'ROI Review';
    const isCentralHovered =
      label === 'Custom Reports' ||
      label === 'cost analysis' ||
      label === 'Voya Solutions' ||
      label === 'tag governance' ||
      label === 'Tag Key Auditor' ||
      label === 'Monthly Tag Maturity' ||
      label === 'Alerts' ||
      label === 'Build Your Own' ||
      label === 'PaaS Analytics' ||
      label === 'Azure Databricks';
    const isSuggEngineHovered = label === 'Forecast Model' || label === 'Anomaly Detection';
    const isIamHovered = label === 'Admin Center';
    if (isCxoHovered) {
      setHoveredIndex(0);
    } else if (isCentralHovered) {
      setHoveredIndex(1);
    } else if (isSuggEngineHovered) {
      setHoveredIndex(2);
    } else if (isIamHovered) {
      setHoveredIndex(3);
    } else {
      setHoveredIndex(null);
    }
  };

  const handleAction = (e, path, label, shouldNavigate = true) => {
    getNotifications();
    e.stopPropagation();
    if (shouldNavigate) {
      navigate(path);
      handleExpand(null);
    }
  };

  useEffect(() => {
    if (pathname) {
      getSelectedPanel(pathname);
    }
  }, [pathname]);

  return (
    <aside className={`${isMobileView ? '!hidden' : ''}`}>
      <div className="w-[20px] h-full text-center">
        {usage &&
          usage.map((item, usageIndex) => {
            const isPanelSelected = usageIndex === panelIndex;
            const isDivider = usageIndex !== usage.length - 1;
            const isPanelHovered = usageIndex === hoveredIndex;
            const isActive = isPanelSelected || isPanelHovered;
            return (
              <div
                key={`${item.label} - ${usageIndex}`}
                className={`${item.minHeight} ${
                  isActive ? (isPanelSelected ? 'bg-[#297cc5]' : 'bg-[#C3DCF3]') : 'bg-[#f2f2f2]'
                } flex items-center justify-center ${
                  isDivider && 'border-gray-400 border-b-2 border-dashed'
                }`}>
                <label
                  className={`[writing-mode:vertical-rl] rotate-180 font-semibold ${
                    isPanelSelected ? 'text-white' : 'text-[#4A5462]'
                  } text-xs tracking-[1px] ${isPanelHovered ? 'text-[#4A5462]' : ''}`}>
                  {item.label}
                </label>
              </div>
            );
          })}
      </div>
      <section className="w-[85px] min-h-[75vh] bg-[#F9F9Fb] flex flex-col items-center z-[5] shadow-lg border-r">
        {sideNavs.map((doc, docIndex) => {
          const { expandMode, label: navLabel, icon, path } = doc;
          const label = navLabel && typeof navLabel === 'string' ? labelFormatter(navLabel) : '';
          const isDivider =
            label === 'ROI Review' || label === 'PaaS Analytics' || label === 'Anomaly Detection';
          const selectedNavClassName =
            (pathname === path && !expandState) ||
            expandState === docIndex ||
            (expandMode &&
              doc.additionalRoute
                .map((route) => (route.childRoute ? [route.path, ...route.childRoute] : route.path))
                .flat(1)
                .includes(pathname))
              ? 'bg-gray-300 text-[#000000] font-normal text-xs '
              : 'text-[#333333]';
          const uniqueClass = `${label}-${docIndex}`;

          if (expandMode) {
            return (
              <div
                className={`w-full hover:bg-sky-100 h-[80px] hover:cursor-pointer ${
                  isDivider && 'border-gray-400 border-b-2 border-dashed'
                }`}
                key={docIndex}
                onMouseEnter={() => handleHoveredPanel(label)}
                onMouseLeave={() => handleHoveredPanel(null)}>
                <nav
                  className={`${uniqueClass} ${selectedNavClassName}  flex items-center flex-col justify-center p-2`}
                  key={`nav-${docIndex + 1}`}
                  onClick={() => handleExpand(docIndex)}>
                  {icon}
                  <label
                    className="text-xs text-[#000000]
                     text-center hover:cursor-pointer capitalize">
                    {label}
                  </label>
                </nav>
              </div>
            );
          } else {
            return (
              navLabel !== 'Build Your Own 2' && (
                <div
                  className={`w-full hover:bg-sky-100 h-[80px] hover:cursor-pointer ${
                    isDivider ? 'border-gray-400 border-b-2 border-dashed' : ''
                  }`}
                  onClick={(e) => handleAction(e, doc.path, doc.label, false)}
                  key={docIndex}
                  onMouseEnter={() => handleHoveredPanel(label)}
                  onMouseLeave={() => handleHoveredPanel(null)}>
                  <Link to={path} key={docIndex} onClick={handleClose} className={uniqueClass}>
                    <nav
                      className={
                        selectedNavClassName +
                        ' h-[80px] flex items-center flex-col justify-center p-2'
                      }
                      key={`nav-${docIndex + 1}`}>
                      {icon}
                      <label className="text-xs text-center text-[#000000] hover:cursor-pointer capitalize">
                        {label}
                      </label>
                    </nav>
                  </Link>
                </div>
              )
            );
          }
        })}
      </section>
      <section
        className={`layout_container_aside_section_two ${expandState ? 'expand' : 'collapse'}`}
        onClick={handleClose}>
        <section className="expandSection shadow transition-all ease-in duration-100 w-60 h-full bg-gray-50 rounded-tr-2xl">
          <div className="text-center pt-4 pb-[0.5rem] px-1">
            <h2 className="m-0 text-lg text-black font-medium pl-2 capitalize w-[90%] text-start tracking-[1px]">
              {expandState && sideNavs[expandState] && sideNavs[expandState].title
                ? labelFormatter(sideNavs[expandState].title)
                : null}
            </h2>
            <div
              className="absolute right-[0px] top-[4px]  w-12 p-4 hover:cursor-pointer"
              onClick={handleClose}>
              <HiChevronDoubleLeft className="text-black text-xl font-bold" />
            </div>
          </div>
          {expandState &&
            sideNavs[expandState] &&
            sideNavs[expandState].additionalRoute &&
            sideNavs[expandState].additionalRoute.map((doc, docIndex) => {
              const selectedNavClassName =
                (doc.childRoute && doc.childRoute.includes(pathname)) || pathname === doc.path
                  ? 'flex items-center px-2 py-2 text-secondary hover:text-gray-600 text-sm bg-gray-200 cursor-pointer border-r-4 border-[#1184C4]'
                  : 'flex items-center px-2 py-2 text-gray-600 text-sm hover:bg-[#ECEFF0] cursor-pointer hover:border-r-4 hover:border-gray-500';
              return (
                <div
                  className={selectedNavClassName}
                  key={docIndex}
                  onClick={(e) => handleAction(e, doc.path, doc.label)}
                  onMouseEnter={() => handleHoveredPanel(doc.label)}
                  onMouseLeave={() => handleHoveredPanel(null)}>
                  <Tooltip target=".nav_label_tool" className="custom_tooltip" />
                  {doc.icon}
                  <p
                    className="pl-3 truncate nav_label_tool tracking-[0.4px]"
                    data-pr-tooltip={doc.label}
                    data-pr-position="right">
                    {doc.label}
                  </p>
                </div>
              );
            })}
        </section>
      </section>
    </aside>
  );
}

export default SideBar;
