import endPoint from './endPoints';
import routerPath from './routerPath';

const constant = {
  ...routerPath,
  endPoint,
  msal_redirect_url: 'https://49.249.168.234/finops-backend/public/api/auth/microsoft'
};

export default constant;
